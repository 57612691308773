
import { Options, Vue, setup } from "vue-class-component";
import { NavBar, Button, CellGroup, Field, Toast, Switch, Form } from "vant";
import { useStore } from "vuex";
import { ref } from "vue";
import {
  appKey,
  url,
  restUrl,
  isHttpDNS,
  useCustomConfig,
  tokenLogin,
  useReplacedMessageContents,
  fixedDeviceId,
  loginInfo
} from "@/const";
import { AllState } from "@/store";
import { useRouter } from "vue-router";
@Options({
  components: {
    NavBar,
    Button,
    CellGroup,
    Field,
    Switch,
    Form
  }
})
export default class Config extends Vue {
  config = setup(() => {
    const store = useStore<AllState>();
    const router = useRouter();

    let appkey = ref(appKey);
    let urlRef = ref(url);
    let restUrlRef = ref(restUrl);
    let isUseReplacedMessageContents = ref(useReplacedMessageContents);
    let isDNS = ref(isHttpDNS);
    let isUseCustomConfig = ref(useCustomConfig);
    let useTokenLogin = ref(tokenLogin);
    let isFixedDeviceId = ref(fixedDeviceId);
    let loginInfoExt = ref(loginInfo);

    // 提交配置
    const onSubmit = () => {
      store.state.IM.connect.close();
      localStorage.setItem("appkey", appkey.value);
      localStorage.setItem(
        "useReplacedMessageContents",
        `${isUseReplacedMessageContents.value}`
      );
      localStorage.setItem("isHttpDNS", `${isDNS.value}`);
      localStorage.setItem("useCustomConfig", `${isUseCustomConfig.value}`);
      localStorage.setItem("isTokenLogin", `${useTokenLogin.value}`);
      localStorage.setItem("isFixedDeviceId", `${isFixedDeviceId.value}`);
      localStorage.setItem("loginInfo", `${loginInfoExt.value}`);
      localStorage.setItem("url", urlRef.value || "");
      localStorage.setItem("restUrl", restUrlRef.value || "");
      localStorage.removeItem("uid");
      localStorage.removeItem("token");
      Toast("配置成功，请重新登录");
      window.location.href = "/login";
    };

    const toLogin = (): void => {
      router.push("/login");
    };

    return {
      appkey,
      urlRef,
      restUrlRef,
      isUseReplacedMessageContents,
      isDNS,
      isUseCustomConfig,
      useTokenLogin,
      isFixedDeviceId,
      loginInfoExt,
      onSubmit,
      toLogin
    };
  });
}
