import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "configWrap" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"margin":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Field = _resolveComponent("Field")!
  const _component_CellGroup = _resolveComponent("CellGroup")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "wel",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.config.toLogin && _ctx.config.toLogin(...args)))
    }, "VolcanO"),
    _createVNode(_component_Form, {
      "label-width": "auto",
      onSubmit: _ctx.config.onSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CellGroup, { inset: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_Field, {
              name: "isUseCustomConfig",
              center: "",
              label: "使用自定义配置"
            }, {
              input: _withCtx(() => [
                _createVNode(_component_Switch, {
                  size: "large",
                  modelValue: _ctx.config.isUseCustomConfig,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.config.isUseCustomConfig) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            (_ctx.config.isUseCustomConfig)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_Field, {
                    name: "appkey",
                    modelValue: _ctx.config.appkey,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.config.appkey) = $event)),
                    center: "",
                    clearable: "",
                    label: "APPKEY",
                    placeholder: "环信appkey"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_Field, {
                    name: "url",
                    modelValue: _ctx.config.urlRef,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.config.urlRef) = $event)),
                    center: "",
                    clearable: "",
                    label: "Url",
                    placeholder: "Msync Url"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_Field, {
                    name: "restUrl",
                    modelValue: _ctx.config.restUrlRef,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.config.restUrlRef) = $event)),
                    center: "",
                    clearable: "",
                    label: "Rest Url",
                    placeholder: "Rest Url"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_Field, {
                    name: "isHttpDNS",
                    center: "",
                    label: "启用HttpDNS"
                  }, {
                    input: _withCtx(() => [
                      _createVNode(_component_Switch, {
                        size: "large",
                        modelValue: _ctx.config.isDNS,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.config.isDNS) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Field, {
                    name: "isUseReplacedMessageContents",
                    center: "",
                    label: "启用替换消息内容"
                  }, {
                    input: _withCtx(() => [
                      _createVNode(_component_Switch, {
                        size: "large",
                        modelValue: _ctx.config.isUseReplacedMessageContents,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.config.isUseReplacedMessageContents) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Field, {
              name: "isTokenLogin",
              center: "",
              label: "使用Token登录"
            }, {
              input: _withCtx(() => [
                _createVNode(_component_Switch, {
                  size: "large",
                  modelValue: _ctx.config.useTokenLogin,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.config.useTokenLogin) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Field, {
              name: "isFixedDeviceId",
              center: "",
              label: "是否固定DeviceId"
            }, {
              input: _withCtx(() => [
                _createVNode(_component_Switch, {
                  size: "large",
                  modelValue: _ctx.config.isFixedDeviceId,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.config.isFixedDeviceId) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Field, {
              name: "loginInfoExt",
              modelValue: _ctx.config.loginInfoExt,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.config.loginInfoExt) = $event)),
              center: "",
              clearable: "",
              placeholder: "login info custom extension",
              label: "登录信息自定义扩展"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            round: "",
            block: "",
            type: "primary",
            "native-type": "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 设置 ")
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ]))
}